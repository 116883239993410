import React from "react"
import { useScrollAnim } from "src/components/hooks/hooks"

export const AboutOverview = ({ data }) => {
  const [trigger, anim] = useScrollAnim()
  // console.log("overview", data)
  return (
    <section className="py-main sc-about-overview" ref={trigger}>
      <div className="container mw-xl">
        <div className={`${anim(1)} col-vision`}>
          <h4 className="caption">{data.vision.label}</h4>
          <ul className="list-unstyled list">
            {data.vision.list.map((li, i) => {
              return (
                <li key={i} className="mb-3">{li.text}</li>
              )
            })}
          </ul>
          {/* <div dangerouslySetInnerHTML={{ __html: data.vision.text }} /> */}
        </div>
        <div className={`${anim(2)} col-mission`}>
          <h4 className="caption">{data.mission.label}</h4>
          <ul className="list-unstyled list">
            {data.mission.list.map((li, i) => {
              return (
                <li key={i} className="mb-3">{li.text}</li>
              )
            })}
          </ul>
          {/* <div dangerouslySetInnerHTML={{ __html: data.mission.text }} /> */}
        </div>
      </div>
    </section>
  )
}
