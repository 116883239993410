import React from "react"

import { useScrollAnim } from "src/components/hooks/hooks"
import { Slider } from "components/anti/slider/slider"

export const AboutPrinciples = ({ data }) => {
  const [trigger, anim] = useScrollAnim()

  return (
    <section className="py-main sc-about-principles" ref={trigger}>
      <div className="container">
        <h2 className={`h1 ${anim(1)}`}>{data.title}</h2>
        <Slider
          visibleInitial={true}
          visibleXxlDown={true}
          visibleXlDown={true}
          visibleLgDown={true}
          visibleMdDown={true}
          visibleSmDown={true}
          arrowsInitial={true}
          arrowsXxlDown={true}
          arrowsXlDown={true}
          arrowsLgDown={true}
          arrowsMdDown={true}
          arrowsSmDown={true}
          showInitial={4}
          showXxlDown={3.25}
          showXlDown={2.75}
          showLgDown={2.125}
          showMdDown={1}
          showSmDown={1}
          className="slider-principles"
        >
          {data.principles.map((data, i) => {
            return (
              <div
                className={`principle-circle-wrapper ${anim(2 + i)}`}
                key={i}
              >
                <div className="principle-circle">
                  <img
                    src={data.image.sourceUrl}
                    className="img-fluid"
                    alt={data.title}
                  />
                  <div className="content">
                    <h4 className="h3">{data.title}</h4>
                    <p>{data.text}</p>
                  </div>
                </div>
              </div>
            )
          })}
        </Slider>
        {/* <div className="d-md-block d-none">
          <div className="row">
            {data.principles.map((data, i) => {
              return (
                <div className="col-md-4">
                  <div
                    className={`principle-circle-wrapper ${anim(2 + i)}`}
                    key={i}
                  >
                    <div className="principle-circle">
                      <img
                        src={data.image.sourceUrl}
                        className="img-fluid"
                        alt={data.title}
                      />
                      <div className="mx-3 mt-3 px-3 text-center">
                        <h4 className="h2">{data.title}</h4>
                        <p>{data.text}</p>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div> */}
      </div>
    </section>
  )
}
