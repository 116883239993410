import React from "react"
import { Image } from "../../anti/image/image"
import { NavTabs, TabPane } from "../../anti/tabs/tabs"
import { useScrollAnim } from "src/components/hooks/hooks"

export const AboutPartners = ({ data, categories }) => {
  const dataCategories = [
    ...categories.map(data => {
      if (data.partners.nodes.length > 0) {
        return { name: data.name, target: data.slug }
      }
    }),
  ]

  const filteredCategories = dataCategories.filter(function(x) {
    return x !== undefined
  })

  const [trigger, anim] = useScrollAnim()

  return (
    <section
      // Tag="div"
      // fluid={data.image.sourceUrl}
      className="py-main sc-about-partners position-relative dark"
    >
      <div className="partners-bg-wrapper">
        <Image
          src={data.image.sourceUrl}
          className="bg-partners"
          ratio="r-16-9"
          alt="bg"
        />
        <div className="bg-gradient h-100 w-100" />
      </div>
      <div className="container" ref={trigger}>
        <h2 className={`h1 ${anim(1)} mb-4 mb-md-5`}>{data.title}</h2>
        <NavTabs
          variant="pills"
          scrollable
          data={filteredCategories}
          className={`${anim(2)} nav-pills-partners`}
        />
        {categories.map((data, i) => {
          return (
            <>
              {data.partners.nodes && (
                <TabPane
                  id={data.slug}
                  className={data.slug === categories[0].slug ? "active" : ""}
                  key={i}
                >
                  <div className="row row-2">
                    {data.partners.nodes.map((data2, i) => {
                      return (
                        <div className="col-6 col-md-3 col-lg-2 mb-3" key={i}>
                          <Image
                            src={data2.partner.partner.logo.sourceUrl}
                            link={data2.partner.partner.url}
                            ratio="r-4-3"
                            className={`${anim(i + 1)} mx-auto`}
                          />
                        </div>
                      )
                    })}
                  </div>
                </TabPane>
              )}
            </>
          )
        })}
      </div>
    </section>
  )
}
