import React from "react"

import { Cover } from "../../anti/cover/cover"

export const AboutCover = ({ data }) => {
  return (
    <div className="sc-about-cover cover-full">
      <Cover
        variant="basic"
        theme="dark"
        img={data.image.sourceUrl}
        imgHeight="h-500px h-lg-600px"
        title={data.title}
        text={data.text}
        className="cover-about animated fadeInUp"
      />
    </div>
  )
}
