import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Seo from "components/seo"
import Layout from "components/layout"

//load components
import { AboutCover } from "components/pages/about/cover"
import { AboutOverview } from "components/pages/about/overview"
import { Features } from "components/global/features/features"
import { AboutPrinciples } from "components/pages/about/principles"
import { AboutPartners } from "components/pages/about/partners"

const About = ({ pageContext, path }) => {
  const lang = pageContext.langKey
  const data = useStaticQuery(GET_ABOUT_DETAILS)
  const blocks = data?.wordPress?.page?.aboutPage
  const partnerCategories = data?.wordPress?.partner_categories?.nodes

  return (
    <Layout lang={lang} path={path}>
      <Seo title="Tentang Kami" />
      <AboutCover data={blocks.cover} />
      <AboutOverview data={blocks.overview} />
      <Features data={blocks.features} />
      <AboutPrinciples data={blocks.principles} />
      <AboutPartners data={blocks.partners} categories={partnerCategories} />
    </Layout>
  )
}

export default About

const GET_ABOUT_DETAILS = graphql`
  query AboutPage {
    wordPress {
      page(id: "about", idType: URI) {
        aboutPage {
          cover {
            text
            title
            image {
              sourceUrl
            }
          }
          features {
            label
            features {
              downloadButton
              text
              buttons {
                text
                url
              }
              image {
                sourceUrl
              }
            }
          }
          overview {
            mission {
              label
              list {
                text
              }
            }
            vision {
              label
              list {
                text
              }
            }
          }
          principles {
            title
            principles {
              text
              title
              image {
                sourceUrl
              }
            }
          }
          partners {
            title
            image {
              sourceUrl
            }
          }
        }
      }
      partner_categories(where: { orderby: DESCRIPTION }, first: 100) {
        nodes {
          name
          slug
          partners(first: 100) {
            nodes {
              slug
              partner {
                partner {
                  logo {
                    sourceUrl
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
